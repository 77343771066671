import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/home"
import Ad from '../components/ad'

import 'bootstrap/dist/css/bootstrap.css';
import 'sweetalert2/dist/sweetalert2.css'

const IndexPage = () => (
  <Layout title="縮短網址產生器">
    <SEO title="縮短網址產生器" description="本網站服務提供縮短網址以代替原來較長的網址，且提供加密及QR code圖片服務。將較長的URL網址縮短，又稱短址、短網址、網址縮短、縮網等。免費隱藏原始網址產生短網址，快速且方便的縮網址產生工具 by hameba。"
          keywords={[`Hameba` ,`hameba` , `ShortUrl`, `短網址`, `縮網`, `短址`, `短網`, `網址縮短`, `縮網`, `QR code`]}
          />
    <Home />
    <Ad/>
  </Layout>
)

export default IndexPage
