import React from "react"
import AdSense from 'react-adsense';
import "./ad.css";

const Ad = () => (
    <div id="google-ad-block">
      <AdSense.Google
        client='ca-pub-2174182058138308'
        slot='6410986527'
        style={{ display: 'block' }}
        format='auto'
        responsive='true'
      />
    </div>
)

export default Ad
