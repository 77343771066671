import React, { Component } from "react";
import "./home.css";
import axios from 'axios';
import Swal from 'sweetalert2';
import QRCode from 'qrcode.react';
import Switch from "react-switch";
import { Link } from 'gatsby';

class Home extends Component {
  // Initialize the state
  constructor(props) {
    super(props);
    this.state = {
      originalUrl:"",
      inputPwd:"",
      inputCode:"",
      captcha:"0000",
      resShortenId:"",
      qrcode:true,
      level:"L",
      loading:false
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.download = this.download.bind(this)
  }

  componentWillMount(){
  }

  componentDidMount() {

  }

  handleChangeCode(){
    this.setState({
      captcha:Math.floor(Math.random()*9999)+9000,
      inputCode:"",
      loading:false
    })
  }

  handleChange(event, key) {
    const obj = {}
    obj[key] = event.target.value
    this.setState(obj)
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({
      loading:true
    })
    const {originalUrl, inputPwd, inputCode} = this.state

    this.axiosPostC(originalUrl, inputCode, inputPwd);
  }

  handleSwitch(checked) {
    this.setState({ qrcode:checked });
  }

  axiosPostC(originalUrl, inputCode, inputPwd){
    const body = {original:originalUrl, verifyCode:inputCode}

    if(inputPwd){
      body.password = inputPwd
    }

    axios({
      method: 'post',
      url: '/api/url/c',
      data: body
    }).then((res)=>{

      if(res.data.success){
        this.setState({
          inputPwd:"",
          resShortenId:res.data.data
        })
      }
      else{
        Swal.fire(
          'Error',
          res.data.error,
          'error'
        )
      }

      this.handleChangeCode()
    });
  }

  download(){ 
    const canvas = document.querySelector('.HpQrcode > canvas');
    this.downloadRef.href = canvas.toDataURL();
    this.downloadRef.download = `qr_${this.state.resShortenId}.png`;
 }

  render() {

    return (
      <div id="main-form">
        <div id="content">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="original-url" className="col-form-label">原始網址<span className="required">*</span></label>
            <textarea className="form-control" id="original-url" rows="3" 
            onChange={e=> this.handleChange(e, "originalUrl")} value={this.state.originalUrl} required></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="input-pwd">加上密碼(最多六碼)</label>
            <input type="password" className="form-control" id="input-pwd" 
            onChange={e=> this.handleChange(e, "inputPwd")} value={this.state.inputPwd} maxLength={6}/>
          </div>
          <div className="form-group">
            <label htmlFor="input-code">驗證碼<span className="required">*</span>
              <img src={`/api/captcha?p=${this.state.captcha}`}/>
              <button type="button" className="btn btn-primary" onClick={()=>this.handleChangeCode()}>更新</button>
            </label>
            <input type="text" className="form-control" id="input-code" 
            onChange={e=> this.handleChange(e, "inputCode")} value={this.state.inputCode} minLength={4} maxLength={4} required/>
          </div>
          <div className="col-auto text-center">
            {
              this.state.loading?
              <button type="submit" className="btn btn-secondary" disabled>轉換中..</button>
              :
              <button type="submit" className="btn btn-success">送出</button>
            }
          </div>
        </form>
        </div>

        <div id="result">
        {
          this.state.resShortenId?
          <div id="result-url" style={{height:110}}>
            <div>
              <form>
              <div className="form-group row">
                <label className="col-4 col-sm-2 col-form-label" style={{maxWidth:95}}>QR Code
                </label>
                <div className="col-3 col-sm-2 col-form-label" style={{maxWidth:95}}>
                  <Switch onChange={e=>this.handleSwitch(e)} checked={this.state.qrcode} />
                </div>
                <label className="col-4 col-sm-2 col-form-label" style={{maxWidth:70}}>Level</label>
                
                <select className=" col-3 col-sm-2 form-control" style={{maxWidth:60}} value={this.state.level} onChange={e=> this.handleChange(e, "level")}>
                  <option value="L">L</option>
                  <option value="M">M</option>
                  <option value="Q">Q</option>
                  <option value="H">H</option>
                </select>
                
              </div>
              </form>
            <h4>短網址：<a href={`/${this.state.resShortenId}`} rel="noopener noreferrer" target="_blank">https://url.hameba.tw/{this.state.resShortenId}</a></h4>
          </div>
        </div>
          :
          null
        }
        {
          this.state.resShortenId && this.state.qrcode?
          <div id="result-qrcode" className="HpQrcode" style={{height:310}}>
            <div>
              <QRCode value={`https://url.hameba.tw/${this.state.resShortenId}`} includeMargin={true} level={this.state.level} size={256}/><br/>
              <a href="#" ref={node => this.downloadRef = node} className="btn btn-info" onClick={()=>this.download()}>download</a>
            </div>
          </div>
          :
          null
        }
        </div>
      </div>
    );
  }
}

export default Home;
